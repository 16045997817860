<script setup>
import { useProjectsFiltersStore } from '~/stores/projects-filters.store';
import { useRoute } from 'vue-router';
import ProjectFilterElement from './filters/filter-element.vue'
import { computed } from 'vue';

const props = defineProps({
  country_id: {
    type: Number,
    required: true
  }
});

const route = useRoute();

const country_id = props.country_id

const filtersStore = useProjectsFiltersStore();
await filtersStore.fetchFilters(country_id).catch((error) => null);
filtersStore.updateFilterInputs(route.params);

const filters = computed(() => filtersStore.filters)
</script>

<template>
	<div class="rounded-lg sticky top-5">
		<div class="w-full flex flex-col bg-white">
			<template v-for="filter in filters" :key="`elm_${filter._id}`">
				<ProjectFilterElement class="flex-grow" :filter="filter" :filtersStore="filtersStore" :useSlug="true" />
			</template>
		</div>
		<div class="sticky bottom-0">
			<NuxtLink :to="localePath(`/${filtersStore.filter_url}`)"
				class="w-full px-10 py-3 bg-indigo-900 rounded-b-lg justify-center items-center gap-2.5 inline-flex  text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
				{{ $t('client.search') }}
			</NuxtLink>
		</div>
	</div>
</template>
